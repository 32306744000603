const config = {
    python: {
        '3.13': {
            'ver': {
                '3.13.1': {
                    'url': 'https://www.python.org/ftp/python/3.13.1/Python-3.13.1.tgz'
                },
                '3.13.0': {
                    'url': 'https://www.python.org/ftp/python/3.13.0/Python-3.13.0.tgz'
                },
            }
        },
        '3.12': {
            'ver': {
                '3.12.8': {
                    'url': 'https://www.python.org/ftp/python/3.12.8/Python-3.12.8.tgz'
                },
                '3.12.7': {
                    'url': 'https://www.python.org/ftp/python/3.12.7/Python-3.12.7.tgz'
                },
                '3.12.6': {
                    'url': 'https://www.python.org/ftp/python/3.12.6/Python-3.12.6.tgz'
                },
                '3.12.5': {
                    'url': 'https://www.python.org/ftp/python/3.12.5/Python-3.12.5.tgz'
                },
                '3.12.4': {
                    'url': 'https://www.python.org/ftp/python/3.12.4/Python-3.12.4.tgz'
                },
                '3.12.3': {
                    'url': 'https://www.python.org/ftp/python/3.12.3/Python-3.12.3.tgz'
                },
                '3.12.2': {
                    'url': 'https://www.python.org/ftp/python/3.12.2/Python-3.12.2.tgz'
                },
                '3.12.1': {
                    'url': 'https://www.python.org/ftp/python/3.12.1/Python-3.12.1.tgz'
                },
                '3.12.0': {
                    'url': 'https://www.python.org/ftp/python/3.12.0/Python-3.12.0.tgz'
                },
            }
        },
        '3.11': {
            'ver': {
                '3.11.11': {
                    'url': 'https://www.python.org/ftp/python/3.11.11/Python-3.11.11.tgz'
                },
                '3.11.10': {
                    'url': 'https://www.python.org/ftp/python/3.11.10/Python-3.11.10.tgz'
                },
                '3.11.9': {
                    'url': 'https://www.python.org/ftp/python/3.11.9/Python-3.11.9.tgz'
                },
                '3.11.8': {
                    'url': 'https://www.python.org/ftp/python/3.11.8/Python-3.11.8.tgz'
                },
                '3.11.7': {
                    'url': 'https://www.python.org/ftp/python/3.11.7/Python-3.11.7.tgz'
                },
                '3.11.6': {
                    'url': 'https://www.python.org/ftp/python/3.11.6/Python-3.11.6.tgz'
                },
                '3.11.5': {
                    'url': 'https://www.python.org/ftp/python/3.11.5/Python-3.11.5.tgz'
                },
                '3.11.4': {
                    'url': 'https://www.python.org/ftp/python/3.11.4/Python-3.11.4.tgz'
                },
                '3.11.3': {
                    'url': 'https://www.python.org/ftp/python/3.11.3/Python-3.11.3.tgz'
                },
                '3.11.2': {
                    'url': 'https://www.python.org/ftp/python/3.11.2/Python-3.11.2.tgz'
                },
                '3.11.1': {
                    'url': 'https://www.python.org/ftp/python/3.11.1/Python-3.11.1.tgz'
                },
                '3.11.0': {
                    'url': 'https://www.python.org/ftp/python/3.11.0/Python-3.11.0.tgz'
                },
            }
        },
        '3.10': {
            'ver': {
                '3.10.16': {
                    'url': 'https://www.python.org/ftp/python/3.10.16/Python-3.10.16.tgz'
                },
                '3.10.15': {
                    'url': 'https://www.python.org/ftp/python/3.10.15/Python-3.10.15.tgz'
                },
                '3.10.14': {
                    'url': 'https://www.python.org/ftp/python/3.10.14/Python-3.10.14.tgz'
                },
                '3.10.13': {
                    'url': 'https://www.python.org/ftp/python/3.10.13/Python-3.10.13.tgz'
                },
                '3.10.12': {
                    'url': 'https://www.python.org/ftp/python/3.10.12/Python-3.10.12.tgz'
                },
                '3.10.11': {
                    'url': 'https://www.python.org/ftp/python/3.10.11/Python-3.10.11.tgz'
                },
                '3.10.10': {
                    'url': 'https://www.python.org/ftp/python/3.10.10/Python-3.10.10.tgz'
                },
                '3.10.9': {
                    'url': 'https://www.python.org/ftp/python/3.10.9/Python-3.10.9.tgz'
                },
                '3.10.8': {
                    'url': 'https://www.python.org/ftp/python/3.10.8/Python-3.10.8.tgz'
                },
                '3.10.7': {
                    'url': 'https://www.python.org/ftp/python/3.10.7/Python-3.10.7.tgz'
                },
                '3.10.6': {
                    'url': 'https://www.python.org/ftp/python/3.10.6/Python-3.10.6.tgz'
                },
                '3.10.5': {
                    'url': 'https://www.python.org/ftp/python/3.10.5/Python-3.10.5.tgz'
                },
                '3.10.4': {
                    'url': 'https://www.python.org/ftp/python/3.10.4/Python-3.10.4.tgz'
                },
                '3.10.3': {
                    'url': 'https://www.python.org/ftp/python/3.10.3/Python-3.10.3.tgz'
                },
                '3.10.2': {
                    'url': 'https://www.python.org/ftp/python/3.10.2/Python-3.10.2.tgz'
                },
                '3.10.1': {
                    'url': 'https://www.python.org/ftp/python/3.10.1/Python-3.10.1.tgz'
                },
                '3.10.0': {
                    'url': 'https://www.python.org/ftp/python/3.10.0/Python-3.10.0.tgz'
                },
            }
        },
        '3.9': {
            'ver': {
                '3.9.21': {
                    'url': 'https://www.python.org/ftp/python/3.9.21/Python-3.9.21.tgz'
                },
                '3.9.20': {
                    'url': 'https://www.python.org/ftp/python/3.9.20/Python-3.9.20.tgz'
                },
                '3.9.19': {
                    'url': 'https://www.python.org/ftp/python/3.9.19/Python-3.9.19.tgz'
                },
                '3.9.18': {
                    'url': 'https://www.python.org/ftp/python/3.9.18/Python-3.9.18.tgz'
                },
                '3.9.17': {
                    'url': 'https://www.python.org/ftp/python/3.9.17/Python-3.9.17.tgz'
                },
                '3.9.16': {
                    'url': 'https://www.python.org/ftp/python/3.9.16/Python-3.9.16.tgz'
                },
                '3.9.15': {
                    'url': 'https://www.python.org/ftp/python/3.9.15/Python-3.9.15.tgz'
                },
                '3.9.14': {
                    'url': 'https://www.python.org/ftp/python/3.9.14/Python-3.9.14.tgz'
                },
                '3.9.13': {
                    'url': 'https://www.python.org/ftp/python/3.9.13/Python-3.9.13.tgz'
                },
                '3.9.12': {
                    'url': 'https://www.python.org/ftp/python/3.9.12/Python-3.9.12.tgz'
                },
                '3.9.11': {
                    'url': 'https://www.python.org/ftp/python/3.9.11/Python-3.9.11.tgz'
                },
                '3.9.10': {
                    'url': 'https://www.python.org/ftp/python/3.9.10/Python-3.9.10.tgz'
                },
                '3.9.9': {
                    'url': 'https://www.python.org/ftp/python/3.9.9/Python-3.9.9.tgz'
                },
                '3.9.8': {
                    'url': 'https://www.python.org/ftp/python/3.9.8/Python-3.9.8.tgz'
                },
                '3.9.7': {
                    'url': 'https://www.python.org/ftp/python/3.9.7/Python-3.9.7.tgz'
                },
                '3.9.6': {
                    'url': 'https://www.python.org/ftp/python/3.9.6/Python-3.9.6.tgz'
                },
                '3.9.5': {
                    'url': 'https://www.python.org/ftp/python/3.9.5/Python-3.9.5.tgz'
                },
                '3.9.4': {
                    'url': 'https://www.python.org/ftp/python/3.9.4/Python-3.9.4.tgz'
                },
                '3.9.3': {
                    'url': 'https://www.python.org/ftp/python/3.9.3/Python-3.9.3.tgz'
                },
                '3.9.2': {
                    'url': 'https://www.python.org/ftp/python/3.9.2/Python-3.9.2.tgz'
                },
                '3.9.1': {
                    'url': 'https://www.python.org/ftp/python/3.9.1/Python-3.9.1.tgz'
                },
                '3.9.0': {
                    'url': 'https://www.python.org/ftp/python/3.9.0/Python-3.9.0.tgz'
                }
            }
        },
        '3.8': {
            'ver': {
                '3.8.20': {
                    'url': 'https://www.python.org/ftp/python/3.8.20/Python-3.8.20.tgz'
                },
                '3.8.19': {
                    'url': 'https://www.python.org/ftp/python/3.8.19/Python-3.8.19.tgz'
                },
                '3.8.18': {
                    'url': 'https://www.python.org/ftp/python/3.8.18/Python-3.8.18.tgz'
                },
                '3.8.17': {
                    'url': 'https://www.python.org/ftp/python/3.8.17/Python-3.8.17.tgz'
                },
                '3.8.16': {
                    'url': 'https://www.python.org/ftp/python/3.8.16/Python-3.8.16.tgz'
                },
                '3.8.15': {
                    'url': 'https://www.python.org/ftp/python/3.8.15/Python-3.8.15.tgz'
                },
                '3.8.14': {
                    'url': 'https://www.python.org/ftp/python/3.8.14/Python-3.8.14.tgz'
                },
                '3.8.13': {
                    'url': 'https://www.python.org/ftp/python/3.8.13/Python-3.8.13.tgz'
                },
                '3.8.12': {
                    'url': 'https://www.python.org/ftp/python/3.8.12/Python-3.8.12.tgz'
                },
                '3.8.11': {
                    'url': 'https://www.python.org/ftp/python/3.8.11/Python-3.8.11.tgz'
                },
                '3.8.10': {
                    'url': 'https://www.python.org/ftp/python/3.8.10/Python-3.8.10.tgz'
                },
                '3.8.9': {
                    'url': 'https://www.python.org/ftp/python/3.8.9/Python-3.8.9.tgz'
                },
                '3.8.8': {
                    'url': 'https://www.python.org/ftp/python/3.8.8/Python-3.8.8.tgz'
                },
                '3.8.7': {
                    'url': 'https://www.python.org/ftp/python/3.8.7/Python-3.8.7.tgz'
                },
                '3.8.6': {
                    'url': 'https://www.python.org/ftp/python/3.8.6/Python-3.8.6.tgz'
                },
                '3.8.5': {
                    'url': 'https://www.python.org/ftp/python/3.8.5/Python-3.8.5.tgz'
                },
                '3.8.4': {
                    'url': 'https://www.python.org/ftp/python/3.8.4/Python-3.8.4.tgz'
                },
                '3.8.3': {
                    'url': 'https://www.python.org/ftp/python/3.8.3/Python-3.8.3.tgz'
                },
                '3.8.2': {
                    'url': 'https://www.python.org/ftp/python/3.8.2/Python-3.8.2.tgz'
                },
                '3.8.1': {
                    'url': 'https://www.python.org/ftp/python/3.8.1/Python-3.8.1.tgz'
                },
                '3.8.0': {
                    'url': 'https://www.python.org/ftp/python/3.8.0/Python-3.8.0.tgz'
                },
            }
        },
        '3.7': {
            'ver': {
                '3.7.17': {
                    'url': 'https://www.python.org/ftp/python/3.7.17/Python-3.7.17.tgz'
                },
                '3.7.16': {
                    'url': 'https://www.python.org/ftp/python/3.7.16/Python-3.7.16.tgz'
                },
                '3.7.15': {
                    'url': 'https://www.python.org/ftp/python/3.7.15/Python-3.7.15.tgz'
                },
                '3.7.14': {
                    'url': 'https://www.python.org/ftp/python/3.7.14/Python-3.7.14.tgz'
                },
                '3.7.13': {
                    'url': 'https://www.python.org/ftp/python/3.7.13/Python-3.7.13.tgz'
                },
                '3.7.12': {
                    'url': 'https://www.python.org/ftp/python/3.7.12/Python-3.7.12.tgz'
                },
                '3.7.11': {
                    'url': 'https://www.python.org/ftp/python/3.7.11/Python-3.7.11.tgz'
                },
                '3.7.10': {
                    'url': 'https://www.python.org/ftp/python/3.7.10/Python-3.7.10.tgz'
                },
                '3.7.9': {
                    'url': 'https://www.python.org/ftp/python/3.7.9/Python-3.7.9.tgz'
                },
                '3.7.8': {
                    'url': 'https://www.python.org/ftp/python/3.7.8/Python-3.7.8.tgz'
                },
                '3.7.7': {
                    'url': 'https://www.python.org/ftp/python/3.7.7/Python-3.7.7.tgz'
                },
                '3.7.6': {
                    'url': 'https://www.python.org/ftp/python/3.7.6/Python-3.7.6.tgz'
                },
                '3.7.5': {
                    'url': 'https://www.python.org/ftp/python/3.7.5/Python-3.7.5.tgz'
                },
                '3.7.4': {
                    'url': 'https://www.python.org/ftp/python/3.7.4/Python-3.7.4.tgz'
                },
                '3.7.3': {
                    'url': 'https://www.python.org/ftp/python/3.7.3/Python-3.7.3.tgz'
                },
                '3.7.2': {
                    'url': 'https://www.python.org/ftp/python/3.7.2/Python-3.7.2.tgz'
                },
                '3.7.1': {
                    'url': 'https://www.python.org/ftp/python/3.7.1/Python-3.7.1.tgz'
                },
                '3.7.0': {
                    'url': 'https://www.python.org/ftp/python/3.7.0/Python-3.7.0.tgz'
                },
            }
        },
        '3.6': {
            'ver': {
                '3.6.15': {
                    'url': 'https://www.python.org/ftp/python/3.6.15/Python-3.6.15.tgz'
                },
                '3.6.14': {
                    'url': 'https://www.python.org/ftp/python/3.6.14/Python-3.6.14.tgz'
                },
                '3.6.13': {
                    'url': 'https://www.python.org/ftp/python/3.6.13/Python-3.6.13.tgz'
                },
                '3.6.12': {
                    'url': 'https://www.python.org/ftp/python/3.6.12/Python-3.6.12.tgz'
                },
                '3.6.11': {
                    'url': 'https://www.python.org/ftp/python/3.6.11/Python-3.6.11.tgz'
                },
                '3.6.10': {
                    'url': 'https://www.python.org/ftp/python/3.6.10/Python-3.6.10.tgz'
                },
                '3.6.9': {
                    'url': 'https://www.python.org/ftp/python/3.6.9/Python-3.6.9.tgz'
                },
                '3.6.8': {
                    'url': 'https://www.python.org/ftp/python/3.6.8/Python-3.6.8.tgz'
                },
                '3.6.7': {
                    'url': 'https://www.python.org/ftp/python/3.6.7/Python-3.6.7.tgz'
                },
                '3.6.6': {
                    'url': 'https://www.python.org/ftp/python/3.6.6/Python-3.6.6.tgz'
                },
                '3.6.5': {
                    'url': 'https://www.python.org/ftp/python/3.6.5/Python-3.6.5.tgz'
                },
                '3.6.4': {
                    'url': 'https://www.python.org/ftp/python/3.6.4/Python-3.6.4.tgz'
                },
                '3.6.3': {
                    'url': 'https://www.python.org/ftp/python/3.6.3/Python-3.6.3.tgz'
                },
                '3.6.2': {
                    'url': 'https://www.python.org/ftp/python/3.6.2/Python-3.6.2.tgz'
                },
                '3.6.1': {
                    'url': 'https://www.python.org/ftp/python/3.6.1/Python-3.6.1.tgz'
                },
                '3.6.0': {
                    'url': 'https://www.python.org/ftp/python/3.6.0/Python-3.6.0.tgz'
                },
            }
        },
        '2.7': {
            'ver': {
                '2.7.18': {
                    'url': 'https://www.python.org/ftp/python/2.7.18/Python-2.7.18.tgz'
                },
                '2.7.17': {
                    'url': 'https://www.python.org/ftp/python/2.7.17/Python-2.7.17.tgz'
                }
            }
        },
        '3.14 Alpha': {
            'ver': {
                '3.14.0a2': {
                    'url': 'https://www.python.org/ftp/python/3.14.0/Python-3.14.0a2.tgz'
                },
                '3.14.0a1': {
                    'url': 'https://www.python.org/ftp/python/3.14.0/Python-3.14.0a1.tgz'
                },
                '3.14.0a0': {
                    'url': 'https://www.python.org/ftp/python/3.14.0/Python-3.14.0a0.tgz'
                },
            }
        },
    },

    os: {
        'Amazon Linux 2': {
            'ver': {
                '1.x': {},
                '2.x': {},
            },
        },
        'Centos': {
            'ver': {
                '9.x': {},
                '8.x': {},
            },
        },
        'Redhat': {
            'ver': {
                '9.x': {},
                '8.x': {},
            },
        },
        'Ubuntu': {
            'ver': {
                '22.x': {},
                '20.x': {},
                '18.x': {},
            },
        },
        'Debian': {
            'ver': {
                '12.x': {},
                '11.x': {},
                '10.x': {},
            },
        },
        'Fedora': {
            'ver': {
                '39': {},
                '38': {},
                '37': {},
            },
        },
    },

    pre: {},

    compile: {},

    post: {},

    ui: {
        checkbox: {
            'hasComments': {
                'label': 'show comments',
            },
            'hasUseAllCpus': {
                'label': 'use all cpus for compilation',
            },
            'hasInstallPackages': {
                'label': 'install required OS packages and developer tools',
            },
            'hasSpeedOptimization': {
                'label': 'enable speed optimization of Python binaries',
            },
            'hasSqliteSupport': {
                'label': 'add sqlite support',
            },
            'hasOpensslSupport': {
                'label': 'add openssl support',
            },
            'hasSharedLibrary': {
                'label': 'enable shared libraries',
            },
            'hasTestBinary': {
                'label': 'run post-test of Python binaries',
            },
            'hasUpdatePip': {
                'label': 'update Pip, Setuptools and Wheel packages',
            },
            'hasSomeLinks': {
                'label': 'add some soft links for comfortable usage',
            },
        },
        select: {
            'selectPythonVer': {
                'label': 'select Python minor version',
            },
            'selectOsVer': {
                'label': 'select OS version',
            },
        },
        input: {
            'prefix': {
                'label': 'folder for Python binaries (--prefix)',
                'placeholder': ' Directory for Python binaries…'
            },
            'sharedLibrary': {
                'label': 'shared library folder',
            },
        },
    },
}

//--------------------------------------------------------------------------------------------
const script = {
    packages: {
        'Amazon Linux 2': [
            'sudo yum -y update',
            'sudo yum -y groupinstall "Development Tools"',
            'sudo yum -y install wget gcc openssl-devel bzip2-devel libffi-devel xz-devel tk-devel'
        ],
        Centos: [
            'sudo dnf -y update',
            'sudo dnf -y groupinstall "Development Tools"',
            'sudo dnf -y install wget gcc openssl-devel bzip2-devel libffi-devel xz-devel tk-devel'
        ],
        Redhat: [
            'sudo dnf -y update',
            'sudo dnf -y groupinstall "Development Tools"',
            'sudo dnf -y install wget gcc openssl-devel bzip2-devel libffi-devel xz-devel tk-devel'
        ],
        Fedora: [
            'sudo dnf -y update',
            'sudo dnf -y groupinstall "Development Tools"',
            'sudo dnf -y install wget gcc openssl-devel bzip2-devel libffi-devel xz-devel tk-devel'
        ],
        Debian: [
            'sudo apt-get update',
            'sudo apt-get upgrade',
            'sudo apt-get install -y make build-essential libssl-dev zlib1g-dev libbz2-dev' +
            ' libreadline-dev libsqlite3-dev wget curl llvm libncurses5-dev libncursesw5-dev' +
            ' xz-utils tk-dev liblzma-dev tk-dev'
        ],
        Ubuntu: [
            'sudo apt-get update',
            'sudo apt-get upgrade',
            'sudo apt-get install -y make build-essential libssl-dev zlib1g-dev libbz2-dev' +
            ' libreadline-dev libsqlite3-dev wget curl llvm libncurses5-dev libncursesw5-dev' +
            ' xz-utils tk-dev liblzma-dev tk-dev'
        ]
    },
    prepare: [
        'cd /tmp/',
        'wget [[url]]',
        'tar xzf Python-[[pythonVer]].tgz',
        'cd Python-[[pythonVer]]',
    ],
    compile_configure: [
        'sudo ./configure --prefix=[[prefix]]/', //--with-pydebug
    ],
    compile_configure__hasSpeedOptimization: [
        '--enable-optimizations --with-lto --with-computed-gotos --with-system-ffi',
    ],
    compile_configure__hasSharedLibrary: [
        '--enable-shared',
    ],
    compile_make: [
        'sudo make',
    ],
    compile_make__hasUseAllCpus: [
        '-j "$(nproc)"',
    ],
    compile_testBinaries: [
        'sudo ./python[[pythonFam]] -m test'
    ],
    compile_testBinaries__hasUseAllCpus: [
        '-j "$(nproc)"',
    ],
    compile_post: [
        'sudo make altinstall',
        'sudo rm /tmp/Python-[[pythonVer]].tgz',
    ],
    pipUpdate: {
        'common': ['sudo [[prefix]]/bin/python[[pythonFam]] -m pip install --upgrade pip setuptools wheel'],
        '2.7': [
            'sudo [[prefix]]/bin/python[[pythonFam]] -m ensurepip --default-pip',
            'sudo [[prefix]]/bin/python[[pythonFam]] -m pip install --upgrade pip setuptools wheel'
        ],
    },
    links: [
        'sudo ln -s [[prefix]]/bin/python[[pythonFam]]        [[prefix]]/bin/python3',
        'sudo ln -s [[prefix]]/bin/python[[pythonFam]]        [[prefix]]/bin/python',
        'sudo ln -s [[prefix]]/bin/pip[[pythonFam]]           [[prefix]]/bin/pip3',
        'sudo ln -s [[prefix]]/bin/pip[[pythonFam]]           [[prefix]]/bin/pip',
        'sudo ln -s [[prefix]]/bin/pydoc[[pythonFam]]         [[prefix]]/bin/pydoc',
        'sudo ln -s [[prefix]]/bin/idle[[pythonFam]]          [[prefix]]/bin/idle',
        'sudo ln -s [[prefix]]/bin/python[[pythonFam]]-config      [[prefix]]/bin/python-config',
    ]
}


//--------------------------------------------------------------------------------------------

const osFam = Object.keys(config.os)[0] // get first one in the list
const selectOptions_osVer_arr = Object.keys(config.os[osFam].ver) // get the recent one
const osVer = selectOptions_osVer_arr[0]

const pythonFam = Object.keys(config.python)[0] // get first one in the list
const selectOptions_pythonVer_arr = Object.keys(config.python[pythonFam].ver) // get the recent one
const pythonVer = selectOptions_pythonVer_arr[0]

const pythonUrl = config.python[pythonFam].ver[pythonVer].url

const prefixBaseFolder = '/opt/python/'

const initValue = {
    prefixBaseFolder: prefixBaseFolder,
    prefixFolder: prefixBaseFolder + pythonVer + '/',
    sharedLibraryFolder: '/usr/local/lib/',
    osFam: osFam,
    osVerSelectOptions: selectOptions_osVer_arr,
    osVer: osVer,
    pythonFam: pythonFam,
    pythonVerSelectOptions: selectOptions_pythonVer_arr,
    pythonVer: pythonVer,
    url: pythonUrl,
    hasComments: true,
    hasInstallPackages: false,
    hasUseAllCpus: true,
    hasSharedLibrary: false,
    hasOpensslSupport: true,
    hasSqliteSupport: false,
    hasSpeedOptimization: true,
    hasTestBinary: false,
    hasUpdatePip: false,
    hasSomeLinks: false,
}

export {initValue, config, script}


// cd /tmp/;
// wget https://www.python.org/ftp/python/3.9.2/Python-3.9.2.tgz;
// tar xzf Python-3.9.2.tgz;
// cd Python-3.9.2;

// sudo ./configure --prefix=/opt/python39 --enable-optimizations --with-lto --with-system-ffi --with-computed-gotos --enable-loadable-sqlite-extensions -march=native;
// sudo make -j $(nproc);
// sudo make altinstall;
// sudo rm /tmp/Python-3.9.2.tgz;

// sudo ln -s /opt/python39/bin/python3.9 /opt/python39/bin/python3;
// sudo ln -s /opt/python39/bin/python3.9 /opt/python39/bin/python;
// sudo ln -s /opt/python39/bin/python3.9-config /opt/python39/bin/python-config;
// sudo ln -s /opt/python39/bin/pydoc3.9 /opt/python39/bin/pydoc;
// sudo ln -s /opt/python39/bin/idle3.9 /opt/python39/bin/idle;
// sudo ln -s /opt/python39/bin/pip3.9 /opt/python39/bin/pip3;
// sudo ln -s /opt/python39/bin/pip3.9 /opt/python39/bin/pip;

// curl https://bootstrap.pypa.io/get-pip.py -o get-pip.py
// python get-pip.py
//
// python -m ensurepip --default-pip
//
// python -m pip install --upgrade pip